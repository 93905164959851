


import {defineComponent, ref} from "vue";
import {useRoute} from "vue-router";

import BankPosInstallmentList from "@/components/payment-methods/bank-pos/bankposinstallment/List.vue"
import BankPosInstallmentAdd from "@/components/payment-methods/bank-pos/bankposinstallment/AddNew.vue"
import Update from "@/components/payment-methods/bank-pos/Update.vue";

export default defineComponent({
    name: "bank-pos-detail",
    components: {
      Update,
      BankPosInstallmentList,
      BankPosInstallmentAdd,
    },
    setup() {
        const route = useRoute();
        const paramID = route.params.id;

        var theDetailType = ref("");

        const detailChanged= (prmDetail)=> {
            theDetailType.value = prmDetail.Type;
        };

        return {
            paramID, detailChanged, theDetailType,
        };
    },
});

