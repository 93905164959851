const posTypes = {
  "TYPES" : [
    {
      Value : 'VIRTUAL',
      Title : 'Sanal',
      BadgeName : 'warning',
    },
    {
      Value : 'REAL',
      Title : 'Gerçek',
      BadgeName : 'success',
    },
  ],
};
export default posTypes;
