

import {computed, defineComponent, ref, watch} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import { Field, Form} from "vee-validate";
import reservationType from "@/core/data/reservationtypes";
import activepassivetypes from "@/core/data/activepassivetypes";
import * as Yup from "yup";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface IFormVals {
    BankPosID: string;
    Type: string;
    Installments: string [];
    InstallmentCount: number;
}
export default defineComponent({
    name: "bank-pos-installment-update",
    components: {
        Field, Form,
    },
    props: {
        itemID: String,
    },

    setup(props) {
        watch(
            () => props.itemID,
            () => {
                store.dispatch(Actions.BANKPOS_INSTALLMENT_DETAILS, {'ID': props.itemID});
            }
        );



        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const modalRef = ref<null | HTMLElement>(null);

        const myDetails = computed(() => {
            let dataComputed = store.getters.bankPosInstallmentDetails
            if (dataComputed !== undefined && dataComputed.ID !== undefined){
              setData(dataComputed);
            }
            return dataComputed;
        });

        var newItemData = ref<IFormVals>({
          BankPosID: "",
          Type: "",
          Installments: resetInstallments(),
          InstallmentCount: 15,
        });

        function resetInstallments() {
          let rv = [];
          for (let i=0; i<15; i++){
            rv.push({"BankCommission":0,"Commission":0,"IsActive":false});
          }
          return rv;
        }

        function getInstallmentsByCount() {
          let rv = [];
          for (let i=0; i<newItemData.value.InstallmentCount; i++){
            rv.push(newItemData.value.Installments[i]);
          }
          return rv;
        }

        const setData = (prmData) => {
            newItemData.value.Type = prmData.Type;
            newItemData.value.BankPosID = prmData.BankPosID;
            newItemData.value.Installments = fillRest(JSON.parse(prmData.Installments), 15) ;
            newItemData.value.InstallmentCount = prmData.InstallmentCount;
            ccvInstalmentCount.value = prmData.InstallmentCount;

            //console.log("Installments " + prmData.InstallmentCount);
        };


        function fillRest(prmInstallments, prmTargetCount) {
          let vvDif = Number(prmTargetCount-prmInstallments.length)
          for (let i=0; i<vvDif; i++) {
            prmInstallments.push({"BankCommission":0,"Commission":0,"IsActive":false});
          }

          return prmInstallments;
        }

        var ccvInstalmentCount = ref(0);


        const validationSchema = Yup.object().shape({
          });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
                 ID: props.itemID,
                 BankPosID: myDetails.value.BankPosID,
                 Type: newItemData.value.Type,
                 Installments: JSON.stringify(getInstallmentsByCount()),
                 InstallmentCount: newItemData.value.InstallmentCount,
            };


            console.log("submit v1 "+JSON.stringify(payload1));

            store.dispatch(Actions.BANKPOS_INSTALLMENT_EDIT, payload1)
                .then(() => {
                    store.dispatch(Actions.BANKPOS_INSTALLMENT_LIST, {BankPosID: myDetails.value.BankPosID});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(modalRef.value);
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getBankPosInstallmentErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        };


        return {
            newItemData, modalRef, submitButtonRef, validationSchema, submit,
            myDetails, reservationType, activepassivetypes, ccvInstalmentCount, fillRest,
        }
    }
});

