
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Update from "@/components/payment-methods/bank-pos/bankposinstallment/Update.vue"
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import reservationType from "@/core/data/reservationtypes";
import getDocumentElement from "@popperjs/core/lib/dom-utils/getDocumentElement";

export default defineComponent({
    name: "installments-list",
    components: {
        Update,
    },
    props: {
       bankPosID: String,
    },
    setup(props) {

        store.dispatch(Actions.BANKPOS_INSTALLMENT_LIST, {BankPosID: props.bankPosID});
        const myBankPosInstallment = computed(() => {
            return store.getters.bankPosInstallmentList;
        });


       function getInsAsArray(prmItem) {
          let objIns = JSON.parse(prmItem.Installments);
           return objIns;
       }

        const deleteInstallment = (ID) => {

            Swal.fire({
                title: 'Emin misiniz?',
                text: "Kaydi silmek işlemi geri alınamaz.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Kaydı Sil',
                cancelButtonText: `Vazgeç`,
                confirmButtonColor: '#9c1d1d',
                cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    store.dispatch(Actions.BANKPOS_INSTALLMENT_DELETE, {ID: ID,})
                        .then(() => {
                            store.dispatch(Actions.BANKPOS_INSTALLMENT_LIST, {BankPosID: props.bankPosID});
                            Swal.fire(
                                'Silindi!',
                                'Kayit silme işlemi tamamlandı.',
                                'success'
                            )
                        }).catch(() => {
                        Swal.fire({
                            text: store.getters.getBankPosInstallmentErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });
                }
            });
        };


        const SelItemID = ref<number>(0);
        function setItems(ID) {
            SelItemID.value=ID;
        }


        return {
            libTools,
            myBankPosInstallment,
            deleteInstallment,
            setItems,
            SelItemID,
            getInsAsArray,
            reservationType,
        };
    },
});
