
import {computed, defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import {ErrorMessage, Field, Form} from "vee-validate";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import posTypes from "@/core/data/postypes";
import currency from "@/core/data/currency";


export default defineComponent({
  name: "operation-transfer-update",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    itemID: String,
  },
  setup(props, ctx) {
    store.dispatch(Actions.BANKPOS_DETAILS, {'ID': props.itemID});

    store.dispatch(Actions.BANKS_LIST, {});
    const dataBanksList = computed(() => {
      console.log(store.getters.banksList)
      return store.getters.banksList;
    });

    let isEmit = true;

    const myDetails = computed(() => {
      let dataComputed =store.getters.bankPosDetails;
      detailTaken(dataComputed);
      return dataComputed;
    });

    const detailTaken = (prmDetail) => {
      if (prmDetail.ID!==undefined) {
        if (isEmit) {
          //isEmit = false;
          ctx.emit('detailChanged', prmDetail);
        }
      }
    }

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);

    const validationSchema = Yup.object().shape({
      BankID: Yup.string().required("Banka Adı girilmemiş"),
      Title: Yup.string().required("Pos Adı girilmemiş"),
      ShortHand: Yup.string().required("Pos Adı girilmemiş"),
      Type: Yup.string().required("Pos Tipi seçilmemiş"),
      Cur: Yup.string().required("Pos Tipi seçilmemiş"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      const payload1 = {
        ID: props.itemID,
        BankID: myDetails.value.BankID,
        Title: myDetails.value.Title,
        ShortHand: myDetails.value.ShortHand,
        Type: myDetails.value.Type,
        Cur: myDetails.value.Cur,
        StoreKey: myDetails.value.StoreKey,
        TerminalID: myDetails.value.TerminalID,
        MerchantID: myDetails.value.MerchantID,
        TerminalProvUserID: myDetails.value.TerminalProvUserID,
        TerminalUserID: myDetails.value.TerminalUserID,
      }

      store.dispatch(Actions.BANKPOS_EDIT, payload1)
          .then(() => {
            isEmit = true;
            store.dispatch(Actions.BANKPOS_DETAILS, {'ID': props.itemID});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(modalRef.value);
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getBankPosErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };

    return {
      myDetails, validationSchema,
      submitButtonRef, modalRef, submit,
      libTools, currency, posTypes, dataBanksList
    };
  },
});
