

import {computed, defineComponent, ref} from "vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {ErrorMessage, Field, Form} from "vee-validate";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Tagify from '@yaireo/tagify';
import {hideModal} from "@/core/helpers/dom";
import {push} from "object-path";
import reservationType from "@/core/data/reservationtypes";
import activepassivetypes from "@/core/data/activepassivetypes";

interface intFormVals {
  Type: string;
  Installments: string [];
  InstallmentCount: number;
}

export default defineComponent({

    name: "new-installment-add",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    props: {
        bankPosID: String,
    },
    setup(props) {


        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const modalRef = ref<null | HTMLElement>(null);

        const formVals = ref<intFormVals>({
            Type: "",
            Installments: resetInstallments(),
            InstallmentCount: 15,
        });

        function resetInstallments() {
          let rv = [];
          for (let i=0; i<15; i++){
           rv.push({"BankCommission":0,"Commission":0,"IsActive":false});
          }
          return rv;
        }

        function getInstallmentsByCount() {
          let rv = [];
          for (let i=0; i<formVals.value.InstallmentCount; i++){
            rv.push(formVals.value.Installments[i]);
          }
          return rv;
        }


        const validationSchema = Yup.object().shape({
            Type: Yup.string().required("Seçilmesi zorunlu"),
        });



        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
                BankPosID: props.bankPosID,
                Type: formVals.value.Type,
                Installments: JSON.stringify(getInstallmentsByCount()),
                InstallmentCount: formVals.value.InstallmentCount,
            }





          store.dispatch(Actions.BANKPOS_INSTALLMENT_INSERT, payload1)
                .then(() => {
                    store.dispatch(Actions.BANKPOS_INSTALLMENT_LIST, {BankPosID: props.bankPosID});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(modalRef.value);
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getBankPosInstallmentErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                    });
                    submitButtonRef.value.disabled = false;
                    submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                });
        };

        return {
            formVals, validationSchema, submit,
            submitButtonRef, modalRef, reservationType, activepassivetypes,
        };
    },
});

